import { Footer } from './footer';
import { NavBar } from './header/nav-bar';
import { Flex } from '../flex';
import { PropsWithChildren, useCallback, useContext, useRef } from 'react';
import { Branding } from 'src/components/layouts/branding';
import { ReactComponent as LuthersRose } from '../../style/images/cyclopediaIcon.svg';
import { ReactComponent as LCMS } from '../../style/images/black_nameplate.svg';
import React from 'react';

const scrollContext = React.createContext({ scroll: () => {} });
export function useScroll() {
    return useContext(scrollContext);
}

export function AppLayout({ children, noFilter }: PropsWithChildren<{ noFilter?: boolean }>) {
    const topRef = useRef<HTMLDivElement>(null);
    const scroll = useCallback(() => {
        topRef.current?.scrollIntoView({
            behavior: 'smooth',
        });
    }, []);

    return (
        <div className='p-3 mb-2 container'>
            {/* <Header /> */}
            <div className='main-content'>
                <div className='bg-header-blend'>
                    <LuthersRose className='cycIcon' />
                    <Flex justification='center' className='container header-bg-image'>
                        <Branding
                            title={
                                <div>
                                    <span className='firstLetter'>C</span>
                                    <span>hristian </span>
                                    <span className='firstLetter'> C</span>
                                    <span>yclopedia</span>
                                </div>
                            }
                        />
                    </Flex>
                    <LCMS className='lcms-logo' />
                    <div ref={topRef}></div>

                    <scrollContext.Provider value={{ scroll }}>
                        <div className='pages'>
                            <NavBar noFilter={noFilter} />
                            {children}
                        </div>
                    </scrollContext.Provider>
                </div>
            </div>
            <Footer />
        </div>
    );
}
