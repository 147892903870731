import { Modal, ModalTitle } from '@lcms/react-modal';
import './easter-egg.scss';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFishingRod } from '@fortawesome/pro-solid-svg-icons';
import { Callback } from '@lcms/helpers/types';
import { Flex } from 'src/components/flex';
import { api } from '../../api';
import { useMount } from '@lcms/react-helpers';
import { images } from './easter-egg-imgs/index';
import AnimatedBubbles from './animated-bubbles';

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const maxWrongGuesses = 6;

export function EasterEgg({ children }: PropsWithChildren<{}>) {
    const [modalVisible, setModalVisible] = useState(false);
    const [eggVisible, setEggVisible] = useState(false);
    const [blueFishRun, setBlueFishRun] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const [roundDone, setRoundDone] = useState<{
        done: boolean;
        won: boolean;
    }>({
        done: false,
        won: false,
    });

    document.addEventListener('selectionchange', function () {
        const selection = document.getSelection();
        const selectedText = selection ? selection.toString().trim() : null;

        if (['Jackson'].includes(selectedText || '')) {
            openEaster();
        }
    });

    useEffect(() => {
        if (eggVisible) {
            window.addEventListener('resize', function () {
                if (window.innerWidth < 720) {
                    setIsMobile(true);
                    console.log('Is mobile');
                } else {
                    setIsMobile(false);
                }
            });
        }

        return () => {
            window.removeEventListener('resize', function () {});
        };
    }, [eggVisible]);

    const openEaster = useCallback(() => {
        setEggVisible(true);
        setModalVisible(true);
        setBlueFishRun(false);
        if (window.innerWidth < 720) {
            setIsMobile(true);
        }
    }, []);

    const closeEaster = useCallback(() => {
        setEggVisible(false);
        setModalVisible(false);
        setBlueFishRun(false);
    }, []);

    useEffect(() => {
        if (eggVisible) {
            document.body.classList.add('easter-egg');
        } else {
            document.body.classList.remove('easter-egg');
            setModalVisible(false);
        }
    }, [eggVisible]);

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);

    if (!eggVisible) return <>{children}</>;

    return (
        <div className='outer-container'>
            {isMobile ? (
                <div>
                    <div className='animated-bubbles'>
                        <AnimatedBubbles />
                    </div>
                    <div id='left-coral' />
                    <div id='right-coral' />
                    {/* <img className='left-coral' src={images.leftCoral} alt='left-coral' /> */}

                    <img className='mobile-sword' src={images.sword} alt='sword' />
                    <Hangman
                        isVisible={modalVisible}
                        close={closeModal}
                        setBlueFishRun={setBlueFishRun}
                        roundDone={roundDone}
                        setRoundDone={setRoundDone}
                    />
                    <FontAwesomeIcon
                        icon={'circle-xmark'}
                        size='2xl'
                        className='m-3 close-egg'
                        style={{ color: '#ffffff' }}
                        onClick={closeEaster}
                    ></FontAwesomeIcon>
                    <img className='sparkle1 mobile' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle2 mobile' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle3 mobile' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle4 mobile' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle5 mobile' src={images.sparkle} alt='sparkle' />
                    <img className='orange-fish' src={images.orangeFish} alt='orange fish' />
                    <img className='orange-fish-baby' src={images.orangeFish} alt='baby orange fish' onClick={openEaster} />
                    <img className='green-fish mobile' src={images.greenFish} alt='green fish' />
                    <img className='yellow-fish mobile' src={images.yellowFish} alt='yellow fish' />
                    <img className='cute-crab mobile' src={images.cuteCrab} alt='cute-crab' />
                    <img className='big-nose mobile' src={images.bigNoseFish} alt='big nose fish' />
                    <img className={blueFishRun ? 'blue-fish-run run' : 'blue-fish-run'} src={images.blueFish} alt='blue fish' />
                </div>
            ) : (
                <div>
                    <div className='animated-bubbles'>
                        <AnimatedBubbles />
                    </div>
                    <div id='left-coral' />
                    <div id='right-coral' />

                    <img className='sword' src={images.sword} alt='sword' />
                    <Hangman
                        isVisible={modalVisible}
                        close={closeModal}
                        setBlueFishRun={setBlueFishRun}
                        roundDone={roundDone}
                        setRoundDone={setRoundDone}
                    />
                    <FontAwesomeIcon
                        icon={'circle-xmark'}
                        size='2xl'
                        className='m-3 close-egg'
                        style={{ color: '#ffffff' }}
                        onClick={closeEaster}
                    ></FontAwesomeIcon>
                    <img className='sparkle1' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle2' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle3' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle4' src={images.sparkle} alt='sparkle' />
                    <img className='sparkle5' src={images.sparkle} alt='sparkle' />
                    <img className='orange-fish' src={images.orangeFish} alt='orange fish' />
                    <img className='orange-fish-baby' src={images.orangeFish} alt='baby orange fish' onClick={openEaster} />
                    <img className='green-fish' src={images.greenFish} alt='green fish' />
                    <img className='yellow-fish' src={images.yellowFish} alt='yellow fish' />
                    <img className='cute-crab' src={images.cuteCrab} alt='cute-crab' />
                    <img className='big-nose' src={images.bigNoseFish} alt='big nose fish' />
                    <img className='squid' src={images.squid} alt='squid' />
                    <img className={blueFishRun ? 'blue-fish-run run' : 'blue-fish-run'} src={images.blueFish} alt='blue fish' />
                </div>
            )}

            {roundDone.done && (
                <>
                    <div className={roundDone.won ? 'winner' : 'loser'}></div>
                    {roundDone.won && <div className='star'></div>}
                </>
            )}

            {children}
        </div>
    );
}

export function Hangman({
    isVisible,
    close,
    setBlueFishRun,
    roundDone,
    setRoundDone,
}: {
    isVisible: boolean;
    close: Callback;
    setBlueFishRun: React.Dispatch<React.SetStateAction<boolean>>;
    roundDone: { done: boolean; won: boolean };
    setRoundDone: React.Dispatch<React.SetStateAction<{ done: boolean; won: boolean }>>;
}) {
    const [guessed, setGuessed] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [word, setWord] = useState('');

    useMount(() => {
        onLoad();
    });

    const onLoad = useCallback(() => {
        setIsLoading(true);
        api.definitions
            .getDefinitionCount({})
            .then((result) => result ?? 0)
            .then((totalEntries) => {
                const randomPage = Math.floor(Math.random() * (totalEntries === 0 ? 1 : Math.ceil(totalEntries / 5)));
                return api.definitions.getDefinitions({
                    page: randomPage,
                    pageSize: 5,
                    mode: 'all',
                });
            })
            .then((result) => {
                if (!result) {
                    console.error('Unable to pull definitions error');
                } else {
                    let cleanedText = '';
                    let infiniteCheck = 0;
                    do {
                        const randomNumber = Math.floor(Math.random() * (result.data.length - 1));
                        const ignoreString = /(&[^;]+?;)|(\.)/g;
                        cleanedText = result.data[randomNumber].title.replaceAll(ignoreString, '');
                        infiniteCheck++;
                    } while (cleanedText.length < 3 && infiniteCheck < 10);
                    setWord(cleanedText);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const guessableLetters = useMemo(() => {
        return letters.split('').map((letter) => ({ letter, present: word.toUpperCase().includes(letter) }));
    }, [word]);

    const correctGuesses = useMemo(() => {
        return guessed.map((x) => (word.toUpperCase().includes(x.toUpperCase()) ? x.toUpperCase() : null));
    }, [guessed, word]);

    const wrongGuesses = useMemo(() => {
        return guessed.filter((x) => !correctGuesses.includes(x.toUpperCase())).length;
    }, [correctGuesses, guessed]);

    const maskedWord = useMemo(() => {
        return word
            .split('')
            .map((letter) =>
                correctGuesses.includes(letter.toUpperCase()) ? letter : !letters.includes(letter.toUpperCase()) ? letter : '_'
            )
            .join('');
    }, [correctGuesses, word]);

    const resetGame = useCallback(() => {
        setRoundDone({ done: false, won: false });
        setGuessed([]);
        onLoad();
    }, [onLoad, setRoundDone]);

    const closeModal = useCallback(() => {
        close();
        resetGame();
        setBlueFishRun(true);
    }, [close, resetGame, setBlueFishRun]);

    useEffect(() => {
        if (!maskedWord.includes('_')) {
            setRoundDone({ done: true, won: true });
        }
    }, [maskedWord, setRoundDone]);

    useEffect(() => {
        if (wrongGuesses >= maxWrongGuesses) {
            setRoundDone({ done: true, won: false });
        }
    }, [setRoundDone, wrongGuesses]);

    return (
        <Modal
            className='hangman-modal text-wrap mobile'
            visible={isVisible}
            CloseButton={
                <button className='border-0 btn btn-outline-primary' onClick={closeModal}>
                    <FontAwesomeIcon icon={faFishingRod}></FontAwesomeIcon>
                </button>
            }
            Header={
                <ModalTitle>
                    {!roundDone.won && roundDone.done
                        ? "You Lost! Click 'Next Word' to continue playing"
                        : roundDone.won
                        ? "Congratulations! You Won! Click 'Next Word' to continue playing"
                        : 'Hangman'}
                </ModalTitle>
            }
            Footer={
                <Flex className='d-flex justify-content-between text-wrap'>
                    <p>Play some hangman or click the fishing rod in the top right to watch some fishies swim around</p>
                    <button className='btn btn-primary' onClick={resetGame}>
                        Next Word
                    </button>
                </Flex>
            }
        >
            <div className='container-fluid'>
                <div className='row'>
                    <div className='px-3 word col-9'>
                        <HangmanWord
                            maskedWord={maskedWord}
                            letters={letters}
                            word={word}
                            roundDone={roundDone}
                            guessed={guessed}
                            setGuessed={setGuessed}
                            isLoading={isLoading}
                        />
                    </div>
                    <div className='px-3 body col-3'>
                        <HangmanBody letters={guessableLetters} guessedLetters={guessed} errors={wrongGuesses} />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export function HangmanWord({
    maskedWord,
    letters,
    word,
    roundDone,
    guessed,
    setGuessed,
    isLoading,
}: {
    maskedWord: string;
    letters: string;
    word: string;
    roundDone: { done: boolean; won: boolean };
    guessed: string[];
    setGuessed: React.Dispatch<React.SetStateAction<string[]>>;
    isLoading: boolean;
}) {
    const addGuess = useCallback(
        (guess: string) => {
            setGuessed((prev) => prev.concat(guess.toUpperCase()));
        },
        [setGuessed]
    );

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (letters.includes(event.key.toUpperCase())) {
                addGuess(event.key);
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [addGuess, letters, setGuessed, word]);

    if (isLoading) {
        return (
            <Flex justification='center' alignment='center' className='h-100'>
                <div className='position-relative'>
                    <EasterEggLoading />
                </div>
            </Flex>
        );
    }

    return (
        <div>
            <p className='masked-word'>
                <pre>{maskedWord}</pre>
            </p>
            {roundDone.done ? <h3 className='text-xl-center font-weight-bold mb-0'>{word}</h3> : ''}
            <div className='btn-group letter-btn-group'>
                {letters.split('').map((letter, index) => (
                    <button
                        key={index}
                        className={`btn btn-primary letter-btns rounded-0 ${roundDone.done || guessed.includes(letter) ? 'disabled' : ''}`}
                        onClick={() => {
                            addGuess(letter);
                        }}
                    >
                        {letter}
                    </button>
                ))}
            </div>
        </div>
    );
}

export function HangmanBody({
    letters,
    guessedLetters,
    errors,
}: {
    letters: { letter: string; present: boolean }[];
    guessedLetters: string[];
    errors: number;
}) {
    const width = 2;
    const color = 'black';
    return (
        <div>
            <div id='easter-body'>
                <svg height='250' width='200' className='figure-container'>
                    {/* <!-- Rod --> */}
                    <line x1='60' y1='20' x2='140' y2='20' stroke={color} strokeWidth={width} />
                    <line x1='140' y1='20' x2='140' y2='50' stroke={color} strokeWidth={width} />
                    <line x1='60' y1='20' x2='60' y2='230' stroke={color} strokeWidth={width} />
                    <line x1='20' y1='230' x2='100' y2='230' stroke={color} strokeWidth={width} />

                    {/* <!-- Head --> */}
                    {errors > 0 && <circle cx='140' cy='70' r='20' stroke={color} strokeWidth={width} />}
                    {/* <!-- Body --> */}
                    {errors > 1 && <line x1='140' y1='90' x2='140' y2='150' stroke={color} strokeWidth={width} />}
                    {/* <!-- Arms --> */}
                    {errors > 2 && <line x1='140' y1='120' x2='120' y2='100' stroke={color} strokeWidth={width} />}
                    {errors > 3 && <line x1='140' y1='120' x2='160' y2='100' stroke={color} strokeWidth={width} />}
                    {/* <!-- Legs --> */}
                    {errors > 4 && <line x1='140' y1='150' x2='120' y2='180' stroke={color} strokeWidth={width} />}
                    {errors > 5 && <line x1='140' y1='150' x2='160' y2='180' stroke={color} strokeWidth={width} />}
                </svg>
            </div>
            <div className='easter-guessed-letters'>
                {letters.map((x, index) => (
                    <p
                        key={index}
                        className={`guessable ${
                            guessedLetters.includes(x.letter) ? (x.present ? 'guessed-present' : 'guessed-not-present') : 'not-guessed'
                        }`}
                    >
                        {x.letter}
                    </p>
                ))}
            </div>
        </div>
    );
}

export function EasterEggLoading() {
    return (
        <div className='loading-block'>
            <div className='loading one'></div>
            <div className='loading two'></div>
            <div className='loading three'></div>
            <div className='loading four'></div>
        </div>
    );
}
