import { DefinitionModal } from '../../components/definitions/definition-modal';
import { AppLayout } from 'src/components/layouts/app-layout';
import { DefinitionsTable } from 'src/components/definitions/definitions-table';
import { useContext } from 'react';
import { definitionsContext } from 'src/frameworks/definitions/definitions-context';
import { WelcomeText } from '../home/welcome-text';
import { Filter } from 'src/components/filter';
import { IndexOffCanvas } from './index-off-canvas';

export function DefinitionsPage() {
    const { isReady } = useContext(definitionsContext);

    return (
        <AppLayout noFilter={!isReady}>
            <DefinitionsTable />
            <DefinitionModal />
            {!isReady && (
                <div className='p-3'>
                    <div className='text-center mb-5'>
                        <h3 className='mb-1'>Getting started</h3>
                        Start by searching by keyword in the search box, or by opening the index with the "Index" button
                        <div className='mx-auto mt-2' style={{ maxWidth: '40rem' }}>
                            <Filter />
                        </div>
                    </div>
                    <WelcomeText />
                </div>
            )}

            <IndexOffCanvas />
        </AppLayout>
    );
}

