import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { RouteAsClass } from '@lcms/react-router-helpers';
import { routes } from './routes';
import { DefinitionsPage } from '../definitions';
import Page from '../Prefaces/1954-preface/index';
import RevisedPrefacePage from '../Prefaces/revised-preface/index';
import WebsitePrefacePage from '../Prefaces/website-preface/index';
import Copyright from '../copyright';
import { NotFound } from '../not-found';
import { DefinitionsProvider } from 'src/frameworks/definitions/definitions-provider';
import { ScrollToTop } from 'src/components/scroll-to-top';

export function Routing() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <DefinitionsProvider>
                <RouteAsClass />
                <Switch>
                    <Route render={() => <Redirect to={routes.definitions} />} exact path={routes.index} />
                    <Route path={routes.definitions} exact component={DefinitionsPage} />
                    <Route path={routes.firstPreface} exact component={Page} />
                    <Route path={routes.revisedPreface} exact component={RevisedPrefacePage} />
                    <Route path={routes.websitePreface} exact component={WebsitePrefacePage} />
                    <Route path={routes.copyright} exact component={Copyright} />
                    <Route render={NotFound} />
                </Switch>
            </DefinitionsProvider>
        </BrowserRouter>
    );
}
