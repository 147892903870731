import { faHouseChimney } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { AppLayout } from 'src/components/layouts/app-layout';
import { routes } from 'src/pages/routing';

export default function Page() {
    return (
        <AppLayout noFilter>
            <div className='p-3'>
                <div className='mb-3'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
                <h3>Preface to the Christian Cyclopedia</h3>
                <hr />
                <p>
                    With the publication of this work, what was the book Lutheran Cyclopedia enters a new era, the electronic text era. In
                    acknowledgement of this change, and of the fact that the Lutheran Cyclopedia was always more than just a reference work
                    about Lutheranism, it was felt a name change was in order. Because we believe that this work will be of help to anyone
                    seeking information about the Christian church, its beliefs, and many of its most important personages, therefore the
                    name has been changed to the Christian Cyclopedia. While there are some articles dealing with other religions and their
                    most important historical figures, for the most part, this work deals with the Christian faith and its most important
                    personages. Greater depth will be found in its treatment of Lutheranism and Lutheran personages, and most especially
                    with those institutions and historical figures associated with The Lutheran Church-Missouri Synod, than is found for
                    other denominations and their important historical figures.
                </p>
                <br />
                <p>
                    This work is more than just an electronic version of the 1975 revised edition of the Lutheran Cyclopedia. Luther
                    Poellot, one of the major in-house editors associated with the preparation of the 1975 edition, continued to prepare
                    emendations, corrections, and new articles from the time of its publication until 1999. Poellot's material has been
                    edited by Charles P. Schaum, who also added and emended some articles, including some in which he received the
                    assistance of his wife Martha. Additional materials for inclusion or addition were prepared by David R. Maxwell and
                    myself.
                </p>
                <br />
                <p>
                    While this work cannot claim to bring up to date the 1975 Lutheran Cyclopedia, it does improve upon it. Like the
                    Lutheran Cyclopedia and its predecessors, it is but another step in the efforts to improve upon its forbearers. With the
                    technological advances that this work makes available, the way that users will consult this work has been changed
                    forever for the better.
                </p>
                <br />
                <p>Paul Jackson</p>
                <div className='mb-3 text-center'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
            </div>
        </AppLayout>
    );
}
