import { Html } from '@lcms/react-helpers';
import { Modal, ModalTitle } from '@lcms/react-modal';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex } from '../flex';
import { CopyToClipboardButton } from '../copy-to-clipboard-button';
import { Loading } from '../loading';
import { LinkType } from 'src/types/link-type';
import { Abbreviation } from 'src/types/abbreviation';
import { Callback, Dictionary } from '@lcms/helpers/types';
import { RenderableContent, getAbbreviationByKey, getDefinitionsByKey } from './definition-modal';
import { RenderedContent } from './rendered-content';
import { TooltipBoundary } from '@lcms/react-tooltips';

export function ContentLinkModal({
    active,
    text,
    hide,
    link,
    abbreviationCache,
}: {
    active: boolean;
    text: string;
    hide: Callback;
    link: { key: string; type: LinkType };
    abbreviationCache: React.RefObject<Dictionary<Promise<Abbreviation | null>>>;
}) {
    const [content, setContent] = useState<RenderableContent[] | null>();
    useEffect(() => {
        if (active) {
            setContent(undefined);
            (link.type === LinkType.Abbreviation ? getAbbreviationByKey : getDefinitionsByKey)(link.key).then((result) => {
                console.log(result);
                setContent(result);
            });
        }
    }, [active, link.key, link.type]);

    const contentRef = useRef<HTMLDivElement>(null);
    const getText = useCallback(() => contentRef.current?.innerText ?? '', []);

    return (
        <Modal
            dialogueClassName='container'
            visible={active}
            Header={
                <ModalTitle>
                    <Html content={text} />
                </ModalTitle>
            }
            CloseButton={<button type='button' className='btn-close' onClick={hide} />}
            Footer={
                <Flex justification='between'>
                    {content?.length ? <CopyToClipboardButton value={getText} /> : <div></div>}
                    <button className='btn btn-dark' onClick={hide}>
                        Close
                    </button>
                </Flex>
            }
        >
            <TooltipBoundary>
                <div className='pb-5' ref={contentRef}>
                    {content === null ? (
                        <div className='text-danger fs-18'>An Error Occurred</div>
                    ) : content ? (
                        <>
                            {content.map((c, i) => (
                                <div key={i} className='mb-2'>
                                    <div key={i} className={`p-1 px-2 rounded ${content.length > 1 ? 'border border-light' : ''}`}>
                                        <RenderedContent content={c} abbreviationCache={abbreviationCache} />
                                    </div>
                                    {content.length > 1 && i < content.length - 1 && <span className='fst-italic'>or</span>}
                                </div>
                            ))}
                            {content.length === 0 && <div className='d-flex justify-content-center opacity-75 fw-bold'>No Matches Found</div>}
                        </>
                    ) : (
                        <Loading />
                    )}
                </div>
            </TooltipBoundary>
        </Modal>
    );
}
