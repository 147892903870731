import { ApiCall, ParameterDefinition } from '@lcms/api-definition';
import endpoints from '../../endpoints';
import { Article } from '../../../types/article';

export type EndpointResponse = {
    definition?: Article;
};

export default new ApiCall<EndpointResponse>().post('definition', {
    route: endpoints.definition,
    params: {
        id: ParameterDefinition.String,
    },
});
