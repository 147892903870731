import { Flex } from 'src/components/flex';
import { Filter } from 'src/components/filter';
import { useQueryStringParameters } from 'src/frameworks/query-strings/use-query-string-parameters';
import { Link } from 'react-router-dom';
import { routes } from 'src/pages/routing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/pro-solid-svg-icons';

export function NavBar({ noFilter }: { noFilter?: boolean }) {
    const { queryStrings } = useQueryStringParameters();
    const { mode, index, filter } = queryStrings;
    const isReady = (mode === 'filter' && filter) || (mode === 'index' && index);

    if (!isReady && noFilter) {
        return null;
    }

    return (
        <div className='header bg-primary'>
            <div className='navbar'>
                <div className='text-white px-1'>
                    {isReady && (
                        <Flex alignment='center' className='h5 mb-0'>
                            <Link to={routes.index} className='btn btn-primary btn-sm me-1' style={{ fontSize: '18pt' }}>
                                <FontAwesomeIcon icon={faHouseChimney} />
                            </Link>
                            <div
                                className='border-start ps-2 border-white'
                                style={{ '--bs-border-width': '2px', '--bs-border-opacity': 0.6 } as React.CSSProperties}
                            >
                                {mode === 'filter' ? <>Showing results for search "{filter}"</> : <>Showing results for index "{index}"</>}
                            </div>
                        </Flex>
                    )}
                </div>
                {!noFilter && (
                    <div className='w-100 w-md-auto'>
                        <Filter />
                    </div>
                )}
            </div>
        </div>
    );
}
