import React from 'react';
import { PaginatedResult } from '@lcms/react-table';
import { Article } from '../../types/article';
import { ArticleSummary } from '../../types/article-summary';

export interface DefinitionsContext {
    isReady: boolean;
    selectedDefinition: Article | null;
    articles: ArticleSummary[] | null | undefined;
    loadDataTable: (page: number, pageSize?: number) => Promise<null | PaginatedResult<ArticleSummary>>;
    fullDefinition: (definition: string | null, title?: string) => void;
}

export const definitionsContext = React.createContext<DefinitionsContext>({
    isReady: false,
    selectedDefinition: null,
    articles: null,
    loadDataTable: () => {
        throw new Error('No Provider');
    },
    fullDefinition: () => {
        throw new Error('No Provider');
    },
});
