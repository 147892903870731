import { ApiCall, ParameterDefinition, OptionalParameterDefinition } from '@lcms/api-definition';
import { PaginatedResult } from '@lcms/react-table';
import endpoints from '../../endpoints';
import { ArticleSummary } from '../../../types/article-summary';

export type EndpointResponse = PaginatedResult<ArticleSummary>

export default new ApiCall<EndpointResponse>().post('getDefinitions', {
    route: endpoints.definitions,
    params: {
        index: OptionalParameterDefinition.String,
        filter: OptionalParameterDefinition.String,
        mode: {
            type: '' as 'index' | 'filter' | 'all'
        },
        page: ParameterDefinition.Number,
        pageSize: OptionalParameterDefinition.Number,
    },
});
