import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            behavior: 'auto',
            top: 0,
        });
    }, [pathname]);

    return null;
}
