import bigNoseFish from "./bigNoseFish.png";
import blueFish from "./cartoonBlueFish.png";
import orangeFish from "./cartoonOrangeFish.png";
import cuteCrab from "./cuteCrab.png";
import sparkle from "./sparkles.png";
import greenFish from "./greenFish.png";
import redFish from "./redFish.png";
import seaHorse from "./seahorse.png";
import squid from "./squid.png";
import star from "./star.png";
import starFish from "./starfish.png";
import sword from "./sword.png";
import uglyCrab from "./uglyCrab.png";
import yellowFish from "./yellowFish.png";

export const images = {
  bigNoseFish,
  blueFish,
  orangeFish,
  cuteCrab,
  sparkle,
  greenFish,
  redFish,
  seaHorse,
  squid,
  star,
  starFish,
  sword,
  uglyCrab,
  yellowFish,
};
