import { Html } from '@lcms/react-helpers';
import { Modal, ModalTitle } from '@lcms/react-modal';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { definitionsContext } from '../../frameworks/definitions/definitions-context';
import { Flex } from '../flex';
import { CopyToClipboardButton } from '../copy-to-clipboard-button';
import { api } from '../../api';
import { useQueryStringParameters } from 'src/frameworks/query-strings/use-query-string-parameters';
import { Loading } from '../loading';
import { Article, Paragraph } from 'src/types/article';
import { Abbreviation, AbbreviationContent } from 'src/types/abbreviation';
import { TooltipBoundary } from '@lcms/react-tooltips';
import { Dictionary } from '@lcms/helpers/types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { RenderedContent } from './rendered-content';
import { ContentLinkModal } from './content-link-modal';
import { LinkType } from 'src/types/link-type';

export function DefinitionModal() {
    const { queryStrings, changeQueryString } = useQueryStringParameters();
    const { selectedDefinition, fullDefinition } = useContext(definitionsContext);

    const abbreviationVisible = useMemo(() => !!queryStrings.abbreviation, [queryStrings.abbreviation]);
    const hideAbbreviations = useCallback(() => {
        changeQueryString({
            abbreviation: undefined,
        });
    }, [changeQueryString]);

    const isVisible = !!queryStrings.definition;
    const hide = useCallback(() => {
        fullDefinition(null);
    }, [fullDefinition]);

    const { goBack } = useHistory();

    const abbreviationCache = useRef<Dictionary<Promise<Abbreviation | null>>>({});

    const contentRef = useRef<HTMLDivElement>(null);
    const getText = useCallback(() => contentRef.current?.innerText ?? '', []);

    return (
        <>
            <Modal
                dialogueClassName='container'
                fullHeight
                visible={isVisible}
                CloseButton={<button className='btn-close' onClick={hide}></button>}
                Header={
                    selectedDefinition?.title ? (
                        <Flex alignment='center'>
                            <button type='button' className='btn btn-link text-decoration-none' onClick={goBack}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <ModalTitle>
                                <Html content={selectedDefinition?.title} />
                            </ModalTitle>
                        </Flex>
                    ) : undefined
                }
                Footer={
                    <Flex justification='between'>
                        {selectedDefinition?.paragraphs?.length ? <CopyToClipboardButton value={getText} /> : <div></div>}
                        <button className='btn btn-dark' onClick={hide}>
                            Close
                        </button>
                    </Flex>
                }
            >
                <TooltipBoundary className='flex-grow-1'>
                    {!selectedDefinition?.paragraphs ? (
                        <Loading />
                    ) : (
                        <div className='px-3' ref={contentRef}>
                            <RenderedContent
                                key={selectedDefinition?.id ?? 'definition'}
                                content={selectedDefinition.paragraphs}
                                abbreviationCache={abbreviationCache}
                            />
                        </div>
                    )}
                </TooltipBoundary>
            </Modal>
            {abbreviationVisible && (
                <ContentLinkModal
                    abbreviationCache={abbreviationCache}
                    active={abbreviationVisible}
                    hide={hideAbbreviations}
                    link={{
                        key: queryStrings.abbreviation ?? '',
                        type: LinkType.Abbreviation,
                    }}
                    text={queryStrings.abbreviation ?? ''}
                />
            )}
        </>
    );
}

export type RenderableContent = Paragraph[] | { order: number; segments: AbbreviationContent[] }[];

export async function getDefinitionsByKey(key: string): Promise<RenderableContent[] | null> {
    const content = await api.definitions
        .getDefinitions({
            page: 0,
            pageSize: 50,
            index: key,
            mode: 'index',
        })
        .then((result) => result?.data)
        .then((result) =>
            result
                ? Promise.all(
                      result.map((r) =>
                          api.definitions
                              .definition({
                                  id: r.id,
                              })
                              .then((r) => r?.definition)
                      )
                  )
                : result
        )
        .then((result) => result?.filter((r) => r) as Article[]);

    if (!content?.length) {
        return null;
    }

    return content.map((c) => {
        return c.paragraphs;
    });
}

export async function getAbbreviationByKey(key: string): Promise<RenderableContent[] | null> {
    const content = await api.abbreviations
        .getAbbreviations({
            page: 0,
            pageSize: -1,
            index: key,
            mode: 'index',
        })
        .then((result) => result?.abbreviations.filter((a) => a.parent.key.toLowerCase() === key.toLowerCase()))
        .then((result) =>
            result
                ? Promise.all(
                      result.map((r) =>
                          api.abbreviations
                              .abbreviation({
                                  id: r.id,
                              })
                              .then((r) => r?.abbreviation)
                      )
                  )
                : result
        )
        .then((result) => result?.filter((r) => r) as Abbreviation[]);

    if (!content?.length) {
        return [];
    }

    return content.map((c) => {
        return [
            {
                order: 0,
                segments: c.content,
            },
        ];
    });
}
