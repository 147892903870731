import { useEffect } from 'react';
import { ReactComponent as BookLoadingAnimationSvg } from './book-loading-animation.svg';
import { Play } from './player';

export default function BookLoadingAnimation() {
    useEffect(() => {
        Play();
    }, []);
    return <BookLoadingAnimationSvg />;
}
