import { notUndefined } from '@lcms/helpers';
import { Flex } from '../flex';
import BookLoadingAnimation from './book-loading-animation';

export function Loading({ text, className }: { text?: string; className?: string }) {
    return (
        <Flex justification='center' className={notUndefined(className) ? className : 'h-100'}>
            <div className='align-self-center text-center'>
                <div>
                    <BookLoadingAnimation />
                </div>
                <span className='display-5'>{text || 'Loading...'}</span>
            </div>
        </Flex>
    );
}
