import { library } from "@fortawesome/fontawesome-svg-core";

import { faCheck, faCircleXmark, faClipboardList, faFishingRod, faGamepad, faHouseChimney, faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faCopyright } from "@fortawesome/pro-regular-svg-icons"

library.add(
    faHouseChimney,
    faInfoCircle,
    faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight,
    faClipboardList,
    faCheck,
    faCopyright,
    faFishingRod,
    faGamepad,
    faCircleXmark
);