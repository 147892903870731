import { ApiCall, OptionalParameterDefinition } from '@lcms/api-definition';
import endpoints from '../../endpoints';

export type EndpointResponse = number;

export default new ApiCall<EndpointResponse>().post('getDefinitionCount', {
    route: endpoints.definitionsCount,
    params: {
        index: OptionalParameterDefinition.String
    },
});
