import { Link } from "react-router-dom";
import { AppLayout } from "src/components/layouts/app-layout";
import { routes } from "../routing/routes";

export function NotFound() {
    return (<>
        <AppLayout>
            <div className="content-pane" style={{ left: '0', right: '0', width: '100%' }}>
                <div className="container-fluid position-relative svgBG mt-5 lost-page" style={{ background: '#F5F2EB' }}>
                    <div className="px-3 py-3 rounded-2 position-relative shadow-sm text-center" style={{ right: 'auto', left: 'auto', top: '0', width: '50%', marginRight: 'auto', marginLeft: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.35' }}>
                        <h3>You seem to be lost</h3>
                        <p>Click <Link to={routes.index} className="link">here</Link> to return Christian Cyclopedia</p>
                    </div>
                </div>
            </div>
        </AppLayout>
    </>);
}