import { useMemo } from 'react';
import { Table, TableRowProps } from '@lcms/react-table';
import { CallbackFor } from '@lcms/helpers/types';
import { Paging } from '../paging';

interface PagedTableProps<T> {
    data: T[] | null | undefined;
    page: number;
    setPage: CallbackFor<[number]>;
    totalPages: number;
    selectRow: CallbackFor<[T]>;
    TableRow: React.FunctionComponent<TableRowProps<T> & { selectRow: CallbackFor<[T]> }>;
}

export function PagedTable<T>({ data, page, setPage, totalPages, selectRow, TableRow }: PagedTableProps<T>) {
    const rowComponent = useMemo(() => (props: TableRowProps<T>) => <TableRow {...props} selectRow={selectRow} />, [TableRow, selectRow]);

    if (!data) {
        return null;
    }

    return (
        <>
            <Table data={data} render={rowComponent} />
            <div className='d-flex justify-content-around'>
                <Paging page={page} setPage={setPage} totalPages={totalPages} />
            </div>
        </>
    );
}
