import { useCallback, useMemo } from 'react';
import { useQueryStringParameters } from 'src/frameworks/query-strings/use-query-string-parameters';
import { useIndices } from 'src/frameworks/index-context.tsx/index-provider';
import { Flex } from 'src/components/flex';

export function IndexOffCanvas() {
    const { changeQueryString, queryStrings } = useQueryStringParameters();
    const indices = useIndices();
    const indexSelection = useMemo(
        () => (queryStrings.index ? indices?.find((i) => i.index === queryStrings.index?.[0]) : undefined),
        [indices, queryStrings.index]
    );
    const indexSubSelection = useMemo(
        () => indexSelection?.subIndices.find((i) => i === queryStrings.index),
        [indexSelection?.subIndices, queryStrings.index]
    );

    const selectOnChange = useCallback(
        (selection?: string) => {
            changeQueryString({
                index: selection,
                filter: undefined,
                mode: 'index',
                page: '0',
            });
        },
        [changeQueryString]
    );

    return (
        <div className='offcanvas offcanvas-start' tabIndex={-1} id='filter-off-canvas' aria-labelledby='filter-off-canvas-label'>
            <div className='offcanvas-header pb-0'>
                <h2 className='offcanvas-title' id='filter-off-canvas-label'>
                    Index
                </h2>
                <button type='button' className='btn-close' data-bs-dismiss='offcanvas' aria-label='Close'></button>
            </div>
            <Flex column className='offcanvas-body'>
                <div className='flex-grow-1'>
                    <Flex wrap className='mb-5'>
                        {indices?.map((index) => (
                            <button key={index.index}
                                type='button'
                                className={`btn indexBtn ${indexSelection !== index ? 'btn-outline-dark' : indexSubSelection ? 'btn-secondary' : 'btn-dark'}`}
                                onClick={() => selectOnChange(index.index)}
                            >
                                {index.index}
                            </button>
                        ))}
                    </Flex>

                    {indexSelection && (
                        <>
                            <h2>Sub-Index</h2>
                            <Flex wrap className='pt-2'>
                                {indexSelection.subIndices.map((index) => (
                                    <button key={index}
                                        type='button'
                                        className={`btn indexBtn ${queryStrings.index !== index ? 'btn-outline-dark' : 'btn-dark'}`}
                                        onClick={() => selectOnChange(index)}
                                    >
                                        {index}
                                    </button>
                                ))}
                            </Flex>
                        </>
                    )}
                </div>
                <button type='button' className='btn btn-dark' onClick={() => selectOnChange()}>
                    Clear Selection
                </button>
            </Flex>
        </div>
    );
}
