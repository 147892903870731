import { useAsyncState } from '@lcms/react-helpers';
import React, { PropsWithChildren, useContext } from 'react';
import { IndexModel } from 'src/types/index-model';

const indexContext = React.createContext({
    indices: [] as IndexModel[] | null | undefined,
});

const { Provider } = indexContext;

export function useIndices() {
    const { indices } = useContext(indexContext);
    return indices;
}

export function IndexProvider({ getIndices, children }: PropsWithChildren<{ getIndices: () => Promise<IndexModel[] | null | undefined> }>) {
    const [indices] = useAsyncState(getIndices);

    return <Provider value={{ indices }}>{children}</Provider>;
}
