export function WelcomeText() {
    return (
        <>
            <p>
                Welcome to the <em>Christian Cyclopedia</em> edited by Erwin Lueker. Originally published in print editions by{' '}
                <a title='www.cph.org' href='https://www.cph.org/'>
                    Concordia Publishing House
                </a>
                , St. Louis, MO USA, <em>Christian Cyclopedia </em>
                has served thousands of students, church professionals, and lay persons as a one-volume compendium of historical and
                theolgical data, ranging from ancient figures to contemporary events.
            </p>
            <p>
                Concordia Publishing House is pleased to present the electronic edition of <em>Christian Cyclopedia</em> as a gift to the
                Church to the glory of the triune God.
            </p>
            <p>
                This Internet version produced by The Lutheran Church&mdash;Missouri Synod allows easy access to the name and terms of
                significant people, places, ideas, and institutions in church history and secular history. Browsers may move to linked
                entries and related concepts among the various sections.
            </p>
        </>
    );
}
