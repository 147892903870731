import { useToggle } from '@lcms/react-helpers';
import React, { useEffect, useState } from 'react';
import { api } from '../../api';
import { LinkType } from 'src/types/link-type';
import { Abbreviation } from 'src/types/abbreviation';
import { Dictionary } from '@lcms/helpers/types';
import { useDefinitions } from 'src/frameworks/definitions/definitions-provider';
import { routes } from 'src/pages/routing';
import { RenderableContent } from './definition-modal';
import { RenderedLink } from './rendered-link';
import { InlineTooltip } from './inline-tooltip';
import { useQueryStringParameters } from 'src/frameworks/query-strings/use-query-string-parameters';
import { ContentLinkModal } from './content-link-modal';

export function ContentLink({
    text,
    link,
    isItalic,
    abbreviationCache,
}: {
    text: string;
    isItalic: boolean;
    link: { type: LinkType; key: string; guid?: string };
    abbreviationCache: React.RefObject<Dictionary<Promise<Abbreviation | null>>>;
}) {
    const [active, show, hide] = useToggle(false);

    const [abbreviationPreview, setAbbreviationPreview] = useState<RenderableContent>();
    const shouldHaveLink = link.type === LinkType.Abbreviation && link.guid;

    useEffect(() => {
        if (link.type === LinkType.Abbreviation && link.guid && abbreviationCache.current) {
            if (!abbreviationCache.current[link.guid]) {
                abbreviationCache.current[link.guid] = api.abbreviations
                    .abbreviation({
                        id: link.guid,
                    })
                    .then((result) => result?.abbreviation ?? null);
            }
            const update = async () => {
                const result = await abbreviationCache.current?.[link.guid ?? ''];
                if (result) {
                    setAbbreviationPreview([
                        {
                            order: 0,
                            segments: result.content,
                        },
                    ]);
                }
            };
            update();
        }
    }, [abbreviationCache, link.guid, link.type]);

    const { fullDefinition } = useDefinitions();
    const { changeQueryString } = useQueryStringParameters();

    return (
        <>
            {link.type === LinkType.Abbreviation ? (
                <InlineTooltip
                    content={
                        abbreviationPreview ? (
                            <>
                                <b>Definition:&nbsp;</b>
                                {abbreviationPreview.map((a) => (
                                    <span key={a.order}>
                                        {a.segments.map((a) => (
                                            <span
                                                key={a.id}
                                                dangerouslySetInnerHTML={{
                                                    __html: a.content.text,
                                                }}
                                            />
                                        ))}
                                    </span>
                                ))}
                            </>
                        ) : (
                            <div>
                                {shouldHaveLink ? (
                                    <>Loading...</>
                                ) : (
                                    <>
                                        <b>Multiple Definitions </b> Click to see all
                                    </>
                                )}
                            </div>
                        )
                    }
                    placement='top'
                >
                    <RenderedLink isItalic={isItalic} show={show} text={text} />
                </InlineTooltip>
            ) : (
                <InlineTooltip
                    content={
                        <>
                            <b>Article:</b> Click to Read More
                        </>
                    }
                    placement='top'
                >
                    <RenderedLink
                        href={routes.definitions + '?definition=' + link.guid}
                        isItalic={isItalic}
                        show={() => {
                            if (link.guid) fullDefinition(link.guid, text);
                            else {
                                changeQueryString({
                                    index: link.key,
                                    filter: undefined,
                                    definition: undefined,
                                    mode: 'index',
                                    page: '0',
                                });
                            }
                        }}
                        text={text}
                    />
                </InlineTooltip>
            )}

            {active && <ContentLinkModal abbreviationCache={abbreviationCache} active={active} hide={hide} link={link} text={text} />}
        </>
    );
}
