import { FetchApi } from '@lcms/api-definition';
import { ApiKey } from './api-key';
import abbreviationsRouter from './endpoints/abbreviations';
import definitionsRouter from './endpoints/definitions';

export const api = FetchApi({
    key: ApiKey,
})
    .useRouter(abbreviationsRouter)
    .useRouter(definitionsRouter);
