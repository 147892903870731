import React from 'react';
import { Abbreviation } from 'src/types/abbreviation';
import { Dictionary } from '@lcms/helpers/types';
import { ContentLink } from './content-link';
import { RenderableContent } from './definition-modal';


export function RenderedContent({
    content, abbreviationCache,
}: {
    content: RenderableContent;
    abbreviationCache: React.RefObject<Dictionary<Promise<Abbreviation | null>>>;
}) {
    return (
        <>
            {content.map((paragraph) => (
                <div className='mb-3' key={'paragraph-' + paragraph.order}>
                    {paragraph.segments.map((segment) => segment.content.link ? (
                        <ContentLink
                            abbreviationCache={abbreviationCache}
                            key={'segment-' + paragraph.order + '-' + segment.order}
                            isItalic={segment.isItalic}
                            link={segment.content.link}
                            text={segment.content.text} />
                    ) : (
                        <span
                            key={'segment-' + paragraph.order + '-' + segment.order}
                            className={segment.isItalic ? 'fst-italic' : undefined}
                            dangerouslySetInnerHTML={{
                                __html: segment.content.text,
                            }} />
                    )
                    )}
                </div>
            ))}
        </>
    );
}
