import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useQueryStringParameters } from 'src/frameworks/query-strings/use-query-string-parameters';
import { Flex } from '../flex';
import { usePropDrivenState } from '@lcms/react-forms';

export function Filter() {
    const { changeQueryString, queryStrings } = useQueryStringParameters();
    const [filterSearch, setFilterSearch] = usePropDrivenState(queryStrings.filter || '');

    const filterTextChange = useCallback(
        (searchValue: string) => {
            setFilterSearch(searchValue?.toUpperCase() || '');
        },
        [setFilterSearch]
    );

    const onSearch = useCallback(
        (value?: string) => {
            changeQueryString({
                filter: value ?? filterSearch,
                index: undefined,
                mode: 'filter',
                page: '0',
            });
        },
        [changeQueryString, filterSearch]
    );

    const onClear = useCallback(() => {
        setFilterSearch('');
        onSearch('');
    }, [onSearch, setFilterSearch]);

    const searchPending = (queryStrings.filter || '') !== (filterSearch || '');

    return (
        <div className='input-group'>
            {filterSearch ? (
                <button className='btn btn-light' type='button' title='Clear Search' onClick={onClear}>
                    <Flex alignment='center'>
                        <FontAwesomeIcon icon={faTimes} />
                    </Flex>
                </button>
            ) : (
                <span className='input-group-text bg-white text-dark'>
                    <FontAwesomeIcon icon={faSearch} />
                </span>
            )}

            <input
                name='filterSearch'
                className='form-control'
                placeholder='Search...'
                value={filterSearch}
                onKeyDown={(e) => (e.code === 'Enter' ? onSearch() : null)}
                onChange={(e) => filterTextChange(e.target.value)}
            />
            {searchPending ? (
                <>
                    <div
                        className='position-absolute start-0 end-0 top-100 bg-white rounded shadow px-1 py-1 fst-italic mt-1 border'
                        style={{ fontSize: '10pt' }}
                    >
                        Click "Search" or press "Enter" to search
                    </div>
                    <button className='btn btn-light' type='button' onClick={() => onSearch()}>
                        <Flex alignment='center'>
                            <FontAwesomeIcon className='me-2' icon={faSearch} /> Search
                        </Flex>
                    </button>
                </>
            ) : (
                <button
                    className='btn btn-light text-nowrap'
                    type='button'
                    data-bs-toggle='offcanvas'
                    data-bs-target='#filter-off-canvas'
                    aria-controls='filter-off-canvas'
                >
                    <FontAwesomeIcon icon={faList} /> Index
                </button>
            )}
        </div>
    );
}
