import { CallbackFor } from '@lcms/helpers/types';
import { Html } from '@lcms/react-helpers';
import { TableRowProps } from '@lcms/react-table';
import { ArticleSummary } from 'src/types/article-summary';

export function TableRow({ item, selectRow }: TableRowProps<ArticleSummary> & { selectRow: CallbackFor<[ArticleSummary]> }) {
    return (
        <tr>
            <td>
                <div className='definitionRow' onClick={() => selectRow(item)}>
                    <span style={{ fontSize: '18pt' }}>
                        <Html content={item.title} />
                    </span>
                    <div className='px-1 mt-1 text-muted'>
                        <Html style={{ height: '4rem', overflow: 'hidden' }} content={item.summary + `<span class='text-light fw-bold'> ... </span>`} /> 
                    </div>
                    <div className='fade-out'></div>
                </div>
            </td>
        </tr>
    );
}
