import { MainContent } from './main-content';
import { Plugins } from './plugins';
import { EasterEgg } from './plugins/easter-egg';

export function App() {
    return (
        <>
            <EasterEgg>
                <MainContent />
            </EasterEgg>
            <Plugins />
        </>
    );
}

export default App;
