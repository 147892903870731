import { faHouseChimney } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { AppLayout } from 'src/components/layouts/app-layout';
import { routes } from 'src/pages/routing';

export default function Page() {
    return (
        <AppLayout noFilter>
            <div className='p-3'>
                <div className='mb-3'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
                <h2>Preface to the Revised Edition</h2>
                <hr />
                <p>
                    As soon as the previous edition of te Lutheran Cyclopedia was published, I received numerous comments, corrections,
                    suggestions, and criticisms. Thus, many changes were entered into a master copy before it was decided to publish a
                    revised edition. I hope that those who use this edition will also send us their suggestions for corrections.
                </p>
                <br />
                <p>
                    The number of entries has been considerably increased, articles from the previous edition were carefully reworked, and
                    the mechanics improved. Subjects on which information would be sought especially in a Lutheran cyclopedia are somewhat
                    more complete than those on which information is available in many other reference works. Thus the length of an article
                    is not necessarily a criterion of importance.
                </p>
                <br />
                <p>
                    Special efforts were made to improve objectivity. I am grateful to those who pointed out unevenness and bias in some
                    entries of the previous edition. Objectivity is not achieved merely by checking each entry for truthfulness. It involves
                    also uniformity of treatment, skill of selection, sympathetic understanding, and a host of other factors. The experience
                    of checking articles of many writers has impressed upon me the need for humble insight into problems of communication
                    within the church.
                </p>
                <br />
                <p>
                    This revision was begun while Otto A. Dorn was manager of Concordia Publishing House. His successor, Ralph L. Reinke,
                    gave special attention to the cyclopedia and with wisdom, patience, and determination saw it to completion. Florence
                    Flachsbart kept in touch with the work as it progressed and served as contact for the editor. We are indebted to many
                    more at Concordia Publishing House who devoted hundreds of hours to this book.
                </p>
                <br />
                <p>
                    Editors of Concordia Publishing House carefully checked all the entries, helped to devise the mechanics, and improved
                    many of the items. These editors included Hilton Oswald, Erich Allwardt, John John, and William Olsen. Luther Poellot
                    painstakingly checked the major portion of the entries and made many corrections and improvements. This task of checking
                    and getting the material into final form was completed by Reinhold Stallmann.
                </p>
                <br />
                <p>
                    The staff of Fuerbringer Library, Concordia Seminary, St. Louis, assisted me and my associates in many research
                    problems. August R. Suelflow, Marvin A. Huggins, and others at Concordia Historical Institute helped with many entries
                    pertaining to Lutheranism in America. The staffs of the Foundation for Reformation Research, of the Dept. of Research
                    and Statistics (LCMS), and of other departments at the Lutheran Building and Concordia Publishing House were always
                    ready to help. Colleagues and students, especially David W. Callies, John E. Groh, and L. Dean Hempelmann, helped obtain
                    information and check entries.
                </p>
                <br />
                <p>
                    There are many who contributed to the production of the cyclopedia whose names are not in the lists: Willard Allbeck,
                    Elaine Allen, Thelda Bertram, Sarah Bischoff, Donna Busch, Dorothy Carmack, Nils A. Dahl, Elizabeth Danker, Anna Dorn,
                    Norine Eggemeyer, William Fiess, Paul Friedrich, Edwin L. Frizen, Arnold H. Grumm, J. David Heino, Nyla Hesterberg, John
                    W. Heussman, Rosemary Lipka, F. Dean Lueking, Geraldine Lutz, Martha MacLean, Jalo E. Nopola, Albert W. Reese, Carl
                    Schalk, Martin Schmidt, Armin Schroeder, Del Schulz, E. P. Schulze, Kristen E. Skydsgaard, Theodore G. Stelzer, Frank C.
                    Streufert, Alice Suelflow, Nellie Turner, Leslie F. Weber, Robert C. Wiederaenders, Wilfried Willer, David Yagow, and
                    others.
                </p>
                <br />
                <p>
                    May she who shared so fully with me the concern and anxiety of producing this book sense in Christ that it is finished.
                    My children lived practically all their lives in the context of this work.
                </p>
                <br />
                <p>Erwin L. Lueker</p>
                <div className='mb-3 text-center'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
            </div>
        </AppLayout>
    );
}
