import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Tooltip, useTooltipBoundary } from '@lcms/react-tooltips';


export function InlineTooltip({
    content, children, placement,
}: PropsWithChildren<{ content: React.ReactNode; placement: 'top' | 'bottom' | 'left' | 'right'; }>) {
    const { boundary, updateFlag } = useTooltipBoundary();
    const [triggerRef, setTriggerRef] = useState<{ current: HTMLElement | null; }>({ current: null });
    const triggerCallback = useCallback((element: HTMLElement | null) => {
        setTriggerRef({ current: element });
    }, []);
    const [visible, setVisible] = useState(false);

    const onMouseEnter = useCallback(() => {
        setVisible(true);
    }, [setVisible]);
    const onMouseLeave = useCallback(() => {
        setVisible(false);
    }, [setVisible]);

    return (
        <div ref={triggerCallback} className='d-inline-block'>
            <Tooltip
                update={updateFlag}
                visible={visible}
                content={content}
                placement={placement}
                boundary={boundary}
                trigger={triggerRef} />
            <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                {children}
            </span>
        </div>
    );
}
