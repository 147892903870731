import { useQueryString } from "@lcms/react-router-helpers";
import { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { QueryStrings } from "src/types/query-strings";

export function useQueryStringParameters() {
    const { push } = useHistory();
    const queryStrings: Partial<QueryStrings> = useQueryString();
    const queryValues = useRef(queryStrings);
    useEffect(() => {
        queryValues.current = queryStrings;
    }, [queryStrings]);

    const changeQueryString = useCallback(
        (params: Partial<QueryStrings>, routeToDef?: string) => {
            push({
                pathname: routeToDef,
                search: `?${Object.entries({
                    ...queryValues.current,
                    ...queryValues.current,
                    ...params,
                })
                    .filter(([,value]) => typeof value !== 'undefined')
                    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                    .join('&')}`,
            });
        },
        [push]
    );

    return {
        queryStrings,
        changeQueryString
    }
}