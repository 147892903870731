import { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ArticleSummary } from 'src/types/article-summary';
import { Article } from 'src/types/article';
import { api } from '../../api';
import { definitionsContext, DefinitionsContext } from './definitions-context';
import { IndexProvider } from '../index-context.tsx/index-provider';
import { useQueryStringParameters } from '../query-strings/use-query-string-parameters';

export function useDefinitions() {
    return useContext(definitionsContext);
}

export function DefinitionsProvider({ children }: PropsWithChildren<{}>) {
    const { queryStrings, changeQueryString } = useQueryStringParameters();
    const [selectedDefinition, setSelectedDefinition] = useState<Article>();
    const [articles, setArticles] = useState<ArticleSummary[] | null>();

    const isReady = useMemo(() => {
        return !!((queryStrings.mode === 'filter' && queryStrings.filter) || (queryStrings.mode === 'index' && queryStrings.index));
    }, [queryStrings.filter, queryStrings.index, queryStrings.mode]);

    const loadDataTable = useCallback(
        async (page: number, pageSize?: number) => {
            if (!isReady) {
                return {
                    data: [],
                    paging: {
                        page: page ?? 0,
                        pageSize: pageSize ?? 10,
                        totalPages: 0,
                        totalRows: 0,
                    },
                };
            }
        

            return api.definitions
                .getDefinitions({
                    index: queryStrings.index,
                    filter: queryStrings.filter,
                    mode: queryStrings.mode === 'filter' ? 'filter' : 'index',
                    page: page,
                    pageSize,
                })
                .then((result) => {
                    setArticles(result?.data ?? null);
                    return result;
                });
        },
        [isReady, queryStrings.filter, queryStrings.index, queryStrings.mode]
    );

    const fullDefinition = useCallback(
        (selectedDefinition: string | null, title?: string) => {
            setSelectedDefinition(
                selectedDefinition && title
                    ? {
                          title: title,
                          id: selectedDefinition,
                          paragraphs: null as any,
                      }
                    : undefined
            );
            changeQueryString({
                definition: selectedDefinition ?? undefined,
            });
        },
        [changeQueryString]
    );

    useEffect(() => {
        if (queryStrings.definition) {
            api.definitions
                .definition({
                    id: queryStrings.definition,
                })
                .then((result) => {
                    if (!result) {
                        console.error('Unable to pull definition');
                    }
                    setSelectedDefinition(result?.definition);
                });
        } else {
            setSelectedDefinition(undefined);
        }
    }, [queryStrings.definition]);

    const context = useMemo(() => {
        const context: DefinitionsContext = {
            selectedDefinition: selectedDefinition || null,
            loadDataTable,
            fullDefinition,
            articles,
            isReady,
        };
        return context;
    }, [selectedDefinition, loadDataTable, fullDefinition, articles, isReady]);

    return (
        <IndexProvider getIndices={() => api.definitions.indices({})}>
            <definitionsContext.Provider value={context}> {children} </definitionsContext.Provider>
        </IndexProvider>
    );
}
