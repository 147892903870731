import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCopyToClipboard } from '@lcms/react-helpers';
import { Flex } from './flex';

export function CopyToClipboardButton({ value }: { value: string | (() => string) }) {
    const { clipboardIsSupported, copied, copy } = useCopyToClipboard(value);

    if (!clipboardIsSupported) {
        return null;
    }

    return (
        <button className='btn btn-link text-decoration-none' onClick={copy}>
            <Flex alignment='center'>
                <div className='me-2'>
                    <FontAwesomeIcon
                        className={`${copied ? 'text-success' : 'text-clipboard'} fs-20`}
                        icon={['fas', copied ? 'check' : 'clipboard-list']}
                    />
                </div>
                <div className='text-start fs-14' style={{ whiteSpace: 'pre' }}>
                    {copied ? 'Copied!' : 'Copy Definition'}
                </div>
            </Flex>
        </button>
    );
}
