import { ApiCall, ParameterDefinition, OptionalParameterDefinition } from '@lcms/api-definition';
import { Paging } from '@lcms/react-table';
import endpoints from '../../endpoints';
import { AbbreviationSummary } from '../../../types/abbreviation-summary';

export type EndpointResponse = {
    abbreviations: AbbreviationSummary[];
    paging: Paging;
};

export default new ApiCall<EndpointResponse>().post('getAbbreviations', {
    route: endpoints.abbreviations,
    params: {
        index: OptionalParameterDefinition.String,
        filter: OptionalParameterDefinition.String,
        mode: {
            type: '' as 'index' | 'filter'
        },
        page: ParameterDefinition.Number,
        pageSize: OptionalParameterDefinition.Number,
    },
});
