import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '../../../components/flex';
import { routes } from '../../../pages/routing';
import { Link } from 'react-router-dom';
import logo from '../../../style/images/blue_nameplate.svg';

export function Footer() {
    return (
        <footer className='text-center footer'>
            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4 mb-2'>
                    <Flex className='justify-content-md-center'>
                        <div className='mx-auto'>
                            <div className='text-center'>
                                <FontAwesomeIcon icon={faCircleInfo} color='#025aa0' />
                                <b> Prefaces</b>
                            </div>
                            <div>
                                <Link to={routes.firstPreface} title='1954 Preface Page' className='link mx-2'>
                                    Preface to the 1954 Edition
                                </Link>
                            </div>
                            <div>
                                <Link to={routes.revisedPreface} title='Revised Preface Page' className='link mx-2'>
                                    Preface to the Revised Edition
                                </Link>
                            </div>
                            <div>
                                <Link to={routes.websitePreface} title='Website Preface Page' className='link mx-2'>
                                    Preface to the Christian Cyclopedia
                                </Link>
                            </div>
                        </div>
                    </Flex>
                </div>
                <div className='col-12 col-md-6 col-lg-4 mb-2'>
                    <Flex className='justify-content-md-center'>
                        <div className='mx-auto'>
                            <small>
                                <b>Edited by</b>
                            </small>
                            <div>Erwin L. Lueker</div>
                            <div>Luther Poellot</div>
                            <div>Paul Jackson</div>
                        </div>
                    </Flex>
                </div>
                <div className='col-12 col-lg-4'>
                    <Flex justification='center'>
                        <div>
                            <small>
                                <b>Internet Version Produced by</b>
                            </small>
                            <br />
                            <a href='https://www.lcms.org/'>
                                <img src={logo} style={{ width: '10rem' }} alt='The Lutheran Church - Missouri Synod' />
                            </a>
                        </div>
                    </Flex>
                </div>
            </div>
            <hr className='gradient' />
            <div className='my-2 text-center' style={{ fontSize: '12.5px' }}>
                <div>
                    <Link to={routes.copyright} title='Copyright Page' className='link'>
                        <sup>&copy;</sup>
                        Concordia Publishing House, 2000, All Rights Reserved. Reproduced with Permission
                    </Link>
                </div>
                <div>
                    <Link to={routes.copyright} title='Copyright Page' className='link'>
                        <sup>&copy;</sup>
                        1954, 1975, 2000 Concordia Publishing House. All rights Reserved. Content Reproduced with Permission
                    </Link>
                </div>
                <div>
                    <a href='https://www.lcms.org/' title='LCMS' className='link'>
                        <span className='mr-3'>
                            <sup>&copy;</sup>
                            {new Date().getFullYear()}
                        </span>{' '}
                        The Lutheran Church&mdash;Missouri Synod. All Rights Reserved.
                    </a>
                </div>
            </div>
        </footer>
    );
}
