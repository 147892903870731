import React, { useCallback } from 'react';
import { Callback } from '@lcms/helpers/types';


export function RenderedLink({ isItalic, show, text, href }: { show: Callback; isItalic: boolean; text: string; href?: string; }) {
    const onClick = useCallback(
        (e: React.MouseEvent) => {
            show();
            e.preventDefault();
            return false;
        },
        [show]
    );

    return (
        <a href={href || '/#'} onClick={onClick} className='position-relative'>
            <span
                className={isItalic ? 'fst-italic' : undefined}
                dangerouslySetInnerHTML={{
                    __html: text,
                }} />
        </a>
    );
}
