import { Paginator, PagingProps } from '@lcms/react-table';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { ArticleSummary } from 'src/types/article-summary';
import { definitionsContext } from '../../frameworks/definitions/definitions-context';
import { PagedTable } from 'src/components/paged-table/paged-table';
import { Loading } from '../loading';
import { useQueryStringParameters } from 'src/frameworks/query-strings/use-query-string-parameters';
import { TableRow } from './definition-table-row';
import { useScroll } from '../layouts/app-layout';

export function DefinitionsTable() {
    const { loadDataTable } = useContext(definitionsContext);
    return <Paginator defaultPageSize={10} load={loadDataTable} DataComponent={DefinitionsPagedTable} />;
}

function DefinitionsPagedTable({ data, isLoading, page, setPage, totalPages }: PagingProps<ArticleSummary>) {
    const { changeQueryString, queryStrings } = useQueryStringParameters();
    const { fullDefinition, isReady } = useContext(definitionsContext);

    const { page: pageInUrl } = queryStrings;
    const parsedPage = useMemo(() => {
        if (!pageInUrl) return 0;
        const parsedPage = parseInt(pageInUrl);
        return isNaN(parsedPage) ? 0 : parsedPage;
    }, [pageInUrl]);

    useEffect(() => {
        setPage(parsedPage);
    }, [parsedPage, setPage]);

    const setPageInUrl = useCallback(
        (newPage: number) => {
            changeQueryString({
                page: newPage.toFixed(0),
            });
        },
        [changeQueryString]
    );

    const selectRow = useCallback(
        (article: ArticleSummary) => {
            fullDefinition(article.id);
        },
        [fullDefinition]
    );

    const {scroll} = useScroll();

    useEffect(() => {
        if (isLoading) {
            scroll();
        }
    }, [isLoading, scroll]);

    if (!isReady) {
        return null;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <PagedTable data={data} page={page} setPage={setPageInUrl} totalPages={totalPages} selectRow={selectRow} TableRow={TableRow} />
        </div>
    );
}
