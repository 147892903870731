import { faHouseChimney } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { AppLayout } from 'src/components/layouts/app-layout';
import { routes } from 'src/pages/routing';

export default function Page() {
    return (
        <AppLayout noFilter>
            <div className='p-3'>
                <div className='mb-3'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
                <h2> Preface to the 1954 Edition</h2>
                <hr />
                <p>
                    The Lutheran Cyclopedia treats important aspects of the thought and life of the church. It includes the following areas:
                    Bible interpretation, systematic theology, church history, life and worship in the church.
                </p>
                <br />
                <p>William F. Arndt, Richard R. Caemmerer, Otto A. Dorn, and Frederick E. Mayer served as editorial advisers.</p>
                <br />
                <p>
                    The following served as consultants: William F. Arndt, Walter A. Baepler, Paul M. Bretscher, Walter E. Buszin, Richard
                    R. Caemmerer, John H. C. Fritz, Theodore Graebner, Theodore Hoyer, John T. Mueller, Lando C. Otto, Jaroslav Pelikan,
                    William G. Polack, Arthur C. Repp, Edward J. Saleska, George V. Schick, Otto H. Schmidt, Lewis W. Spitz, August R.
                    Suelflow, John M. Weidenschilling, Emil C. Weis, Henry F. Wind.
                </p>
                <br />
                <p>
                    The following supplied information and material, gave suggestions, or assisted the editor in other ways: John Bajus,
                    Emil M. Biegener, Lorenz Blankenbuehler, T. O. Burntvedt, George Dolak, Elmer E. Foelber, William Graumann, Herman
                    Harms, R. W. Heikkinen, Fred Hortig, Alfred Jensen, Hans C. Jersild, Julia Koenig, Herman H. Koppelmann, Karl Kretzmann,
                    Robert Lange, P. G. Lindhardt, Anna Marie Lueker, Carl E. Lund-Quist, Enok Mortensen, Martin J. Neeb, Paul C. Nyholm,
                    Arthur C. Piepkorn, F. Eppling Reinartz, A. A. Uppala, John Wargelin, Gilbert K. Wenger, Walter F. Wolbrecht, Leonard C.
                    Wuerffel, Elmer C. Zimmermann.
                </p>
                <br />
                <p>
                    Materials from Concordia Cyclopedia (CPH, 1927) were adapted and used. Theodore Graebner first suggested and outlined
                    this work and served as a member of the editorial board until 1923. Ludwig Fuerbringer and Theodore Engelder served as
                    editors from the beginning, and Paul E. Kretzmann replaced Theodore Graebner. The associate editors were Frederick
                    Brand, William Dallmann, John H. C. Fritz, Theodore Graebner, Adolph Haentzschel, Edward Koehler, Karl Kretzmann, George
                    W. Mueller, John T. Mueller, H. C. F. Otte, Theodore H. Schroedel, Franz C. Verwiebe. Some articles were written by
                    William H. Behrens, John S. Bradac, Carl J. A. Hoffmann, J. A. Moldstad, H. K. Moussa, Frederick Wenger. Notes with
                    varied suggestions by Ludwig Fuerbringer and other editors of Concordia Cyclopedia were made available to the editor.
                </p>
                <br />
                <p>
                    In making a list of biographies it was considered best to follow the practice which places contemporary men into works
                    like Who's Who rather than cyclopedias.
                </p>
                <br />
                <p>May the Lord of the church find use for the efforts which men who love Him have given to this work.</p>
                <br />
                <br />
                <p>
                    Erwin L. Lueker <br />
                    St. Paul's College, Concordia, Missouri <br />
                    February 1, 1954
                </p>
                <div className='mb-3 text-center'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
            </div>
        </AppLayout>
    );
}
