import { Link } from 'react-router-dom';
import { AppLayout } from 'src/components/layouts/app-layout';
import { routes } from '../routing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseChimney } from '@fortawesome/pro-solid-svg-icons';

export default function Page() {
    return (
        <AppLayout noFilter>
            <div className='p-3'>
                <div className='mb-3'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
                <h2>Comments, Copyrights, and Permissions</h2>
                <hr />
                <br />
                <p>
                    <a title='www.cph.org' href='http://www.cph.org'>
                        Concordia Publishing House
                    </a>
                    , the publishing arm of The Lutheran Church&mdash;Missouri Synod, is pleased to make available in partnership with the
                    Synod this electronic version of Christian Cyclopedia.
                </p>
                <br />
                <p>
                    The editors at Concordia Publishing House welcome your comments and suggestions for improving the content of this
                    electronic edition. Contact us at{' '}
                    <a title='Email us' href='mailto:ChristianCyclopedia@cph.org?subject=Christian Cyclopedia'>
                        ChristianCyclopedia@cph.org.
                    </a>
                </p>
                <br />
                <p>
                    All rights reserved. No part of this material may be printed, reproduced, stored in a retrieval system, or transmitted,
                    in any form or by any means, electronic, mechanical, printed, photocopying, recording or otherwise, without the prior
                    written permission of Concordia Publishing House. For copyright permission, please contact the
                    Contracts/Rights/Permissions Department at{' '}
                    <a title='Email us' href='mailto:Copyrights@cph.org?subject=Christian Cyclopedia'>
                        Copyrights@cph.org
                    </a>{' '}
                    or toll-free 800-325-0191.
                </p>
                <br />
                <p>
                    Concordia Publishing House, St. Louis, Missouri <br />
                    Concordia Publishing House Ltd., London E. C. 1 <br />
                    Original Edition Copyright © 1954 Concordia Publishing House <br />
                    Revised edition Copyright © 1975 Concordia Publishing House <br />
                    Christian Cyclopedia Electronic Edition Copyright © 2000 Concordia Publishing House <br />
                    Manufactured in the United States of America <br />
                    Content Reproduced with Permission <br />
                </p>
                <div className='mb-3 text-center'>
                    <Link to={routes.index} className='text-decoration-none'>
                        <FontAwesomeIcon icon={faHouseChimney} /> Home
                    </Link>
                </div>
            </div>
        </AppLayout>
    );
}
