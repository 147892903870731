import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useArray } from '@lcms/react-helpers';

export function Paging({ page, setPage, totalPages }: { page: number; totalPages: number; setPage: (page: number) => void }) {
    const array = useArray(8)
        .map((_, i) => page + (i - 2))
        .filter((i) => i >= 0 && i < totalPages);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'ArrowRight' && page < totalPages - 1) {
                setPage(page + 1);
            }
            if (event.key === 'ArrowLeft' && page >= 1) {
                setPage(page - 1);
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [page, setPage, totalPages]);

    return (
        <div className='table-btns'>
            <div className='btn-group'>
                <button className='btn btn-secondary table-paging-btns' disabled={page < 1} onClick={() => setPage(0)}>
                    <FontAwesomeIcon icon={['far', 'angle-double-left']} />
                </button>
                <button className='btn btn-secondary table-paging-btns' disabled={page < 1} onClick={() => setPage(page - 1)}>
                    <FontAwesomeIcon icon={['far', 'angle-left']} className='mx-1' />
                </button>
                {array.map((v) => (
                    <button
                        key={v}
                        className={`btn ${v === page ? 'btn-dark' : 'btn-light'} table-paging-btns`}
                        onClick={() => setPage(v)}
                        disabled={v === page}
                    >
                        {v + 1}
                    </button>
                ))}
                <button className='btn btn-secondary table-paging-btns' disabled={page >= totalPages - 1} onClick={() => setPage(page + 1)}>
                    <FontAwesomeIcon icon={['far', 'angle-right']} className='mx-1' />
                </button>
                <button
                    className='btn btn-secondary table-paging-btns'
                    disabled={page >= totalPages - 1}
                    onClick={() => setPage(totalPages - 1)}
                >
                    <FontAwesomeIcon icon={['far', 'angle-double-right']} />
                </button>
            </div>
        </div>
    );
}
