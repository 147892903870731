import { ApiCall, ParameterDefinition } from '@lcms/api-definition';
import endpoints from '../../endpoints';
import { Abbreviation } from '../../../types/abbreviation';

export type EndpointResponse = {
    abbreviation?: Abbreviation;
};

export default new ApiCall<EndpointResponse>().post('abbreviation', {
    route: endpoints.abbreviation,
    params: {
        id: ParameterDefinition.String,
    },
});
