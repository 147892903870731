import { Api } from '@lcms/api-definition';
import definitionEndpoint from './definition-endpoint';
import definitionsEndpoint from './definitions-endpoint';
import indicesEndpoint from './indices-endpoint';
import definitionCountEndpoint from './definition-count-endpoint';

export default Api.getRouter()
    .use(definitionEndpoint.inNamespace('definitions').toDefinition())
    .use(definitionsEndpoint.inNamespace('definitions').toDefinition())
    .use(definitionCountEndpoint.inNamespace('definitions').toDefinition())
    .use(indicesEndpoint.inNamespace('definitions').toDefinition());
